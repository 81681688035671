<template>
  <div>
    <b-row class="mb-3">
      <b-col sm="12" md="12" lg="12">
        <b-form-group
          class="mb-3"
          label="Search :"
          label-for="search"
          description="Searchable : Promo Code, Email, Name"
        >
          <b-input-group>
            <b-form-input
              id="search"
              type="search"
              v-model="filterText"
              placeholder="Promo Code, Email or Name of user whose used Promo Code"
              @keypress="doFilter"
              @keyup.delete="doFilter"
            >
            </b-form-input>
            <b-input-group-append>
              <b-button
                variant="secondary"
                @click="resetFilter"
                type="button"
              >
                Reset
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>
    <vuetable
      ref="vuetable"
      @vuetable:load-error="handleLoadError"
      :api-url="apiUrl"
      :http-options="HttpOptions"
      :fields="fields"
      pagination-path=""
      :muti-sort="true"
      :sort-order="sortOrder"
      :append-params="moreParams"
      @vuetable:pagination-data="onPaginationData"
    >
      <template slot="code-slot" slot-scope="prop">
        <b-badge pill variant="dark" class="badge--code p-3">{{ prop.rowData.code }}</b-badge>
      </template>
      <template slot="period-slot" slot-scope="prop">
        <span :style="[timeNow >= prop.rowData.end_to ? {'color' : 'red'} : {'color' : 'green'}]"
        >{{ prop.rowData.start_from }}</span> <br>
        <span :style="[timeNow >= prop.rowData.end_to ? {'color' : 'red'} : {'color' : 'green'}]"
        > - </span> <br>
        <span :style="[timeNow >= prop.rowData.end_to ? {'color' : 'red'} : {'color' : 'green'}]"
        >{{ prop.rowData.end_to }}</span>
      </template>
      <template slot="user-slot" slot-scope="prop">
        <span v-if="prop.rowData.users.name === '-'"> - </span>
        <span v-else>
          <router-link :to="onAction('view-user', prop.rowData, prop.rowIndex)" target="_blank">{{ prop.rowData.users.name }} ({{ prop.rowData.users.email }})</router-link>
        </span>
      </template>
      <template slot="used-slot" slot-scope="prop">
        <span>{{ prop.rowData.created_at }}</span>
      </template>
    </vuetable>
    <div class="vuetable-pagination ui basic segment grid">
      <vuetable-pagination-info ref="paginationInfo"></vuetable-pagination-info>

      <vuetable-pagination
        ref="pagination"
        @vuetable-pagination:change-page="onChangePage"
      ></vuetable-pagination>
    </div>
  </div>
</template>

<script>
import accounting from "accounting";
import Vue from "vue";
import Vuetable from "vuetable-2/src/components/Vuetable";
import VuetablePagination from "vuetable-2/src/components/VuetablePagination";
import VuetablePaginationInfo from "vuetable-2/src/components/VuetablePaginationInfo";
import moment from "moment/src/moment";
Vue.use(Vuetable);

export default {
  components: {
    Vuetable,
    VuetablePagination,
    VuetablePaginationInfo,
  },
  prop: {
    rowData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      statusOptions: [
        { text: 'Pilih Status Transaksi', value: '' },
        { text: 'Dibatalkan', value: 'cancelled' },
        { text: 'Success', value: 'success' },
        { text: 'Expired', value: 'expired' },
        { text: 'Menunggu Pembayaran', value: 'pending' },
      ],
      status: '',
      startDate: "",
      endDate: "",
      isLoading: false,
      filterText: "",
      timeNow: moment().format("YYYY-MM-DD hh:mm:ss"),
      organization: '',
      module: '',
      code_type: '',
      organizationOptions: [],
      errors: {
        code: "",
        message: "",
        status: "",
      },
      apiUrl:
        process.env.VUE_APP_SECRET + process.env.VUE_APP_CONFIG + `code?prefix=${this.$route.params.id}`,
      HttpOptions: {
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.getItem("access_token"),
        },
      },
      sortOrder: [
        {
          field: "created_at",
          sortField: "promotion_codes.created_at",
          direction: "desc",
        },
      ],
      moreParams: {},
      fields: [
        {
          name: "code-slot",
          title: "Promo Code",
        },
        {
          name: "name",
          title: "Promo Name",
        },
        {
          name: "period-slot",
          title: "Promo Period",
        },
        {
          name: "amount",
          title: "Nilai Promosi",
        },
        {
          name: "min_amount",
          title: "Min Transaksi",
        },
        {
          name: "max_amount",
          title: "Max Transaksi",
        },
        {
          name: "user-slot",
          title: "Used By",
        },
        {
          name: "used-slot",
          title: "Used At",
        }
      ],
    };
  },
  methods: {
    doFilter() {
      this.$events.fire("filter-set", this.filterText);
    },
    doFilterModule () {
      this.$events.$emit('module-set', this.module)
    },
    onModuleFilterSet () {
      this.moreParams = {
        'filter': this.filterText  === '' ? null : this.filterText,
        'start_date': this.startDate === '' ? null : this.startDate,
        'end_date': this.endDate === '' ? null : this.endDate,
        'module': this.module === '' ? null : this.module,
        'code_type': this.code_type === '' ? null : this.code_type
      }
      Vue.nextTick( () => this.$refs.vuetable.refresh())
    },
    doFilterCodeType () {
      this.$events.$emit('code-type-set', this.code_type)
    },
    onCodeTypeFilterSet () {
      this.moreParams = {
        'filter': this.filterText  === '' ? null : this.filterText,
        'start_date': this.startDate === '' ? null : this.startDate,
        'end_date': this.endDate === '' ? null : this.endDate,
        'module': this.module === '' ? null : this.module,
        'code_type': this.code_type === '' ? null : this.code_type
      }
      Vue.nextTick( () => this.$refs.vuetable.refresh())
    },
    doDateFilter(value){
      this.startDate = value.startDate;
      this.endDate = value.endDate;
      this.$events.fire('date-set', [this.startDate, this.endDate])
    },
    onDateSet()  {
      this.start_from = this.startDate;
      this.end_to = this.endDate;
      this.moreParams = {
        'filter': this.filterText  === '' ? null : this.filterText,
        'start_date': this.startDate === '' ? null : this.startDate,
        'end_date': this.endDate === '' ? null : this.endDate,
        'module': this.module === '' ? null : this.module,
        'code_type': this.code_type === '' ? null : this.code_type
      }
      Vue.nextTick( () => this.$refs.vuetable.refresh())
    },
    resetFilter() {
      this.filterText = ""; // clear the text in text input
      this.$events.fire("filter-reset");
    },
    weight(value) {
      return value + " Gram";
    },
    handleLoadError(error) {
      this.errors.code = error.response.data.meta.code;
      this.errors.message = error.response.data.meta.message;
      this.errors.status = error.response.data.meta.code;
      if (this.errors.code == 401) {
        if (localStorage.getItem("access_token") != null) {
          localStorage.removeItem("access_token");
          this.$swal
            .fire(
              "Your session expired!",
              "Your session has expired. Please login again to access this page!",
              "error"
            )
            .then(() => {
              this.$router.push("/login");
            });
        }
      } else if (this.errors.code == 403) {
        this.$router.push("/403");
      } else if (this.errors.code == 500) {
        this.$router.push("/500");
      }
    },
    resetDateFilter() {
      this.startDate = "";
      this.endDate = "";
      this.$events.$emit("date-filter-reset");
    },
    formatNumber(value) {
      return "Rp." + accounting.formatNumber(value, 2);
    },
    onChangePage(page) {
      this.$refs.vuetable.changePage(page);
    },
    onPaginationData(paginationData) {
      this.$refs.pagination.setPaginationData(paginationData);
      this.$refs.paginationInfo.setPaginationData(paginationData);
    },
    onAction(action, data) {
      if (action == 'view-user') {
        return { name: 'Detail End User', params: { id: btoa(data.users.id)} }
      }else if (action == "detail-item") {
        this.$router.push({
          name: "Detail Promo Code",
          params: { id: data.id },
        });
      }
    },
    onFilterSet() {
      this.moreParams = {
        'filter': this.filterText  === '' ? null : this.filterText,
        'start_date': this.startDate === '' ? null : this.startDate,
        'end_date': this.endDate === '' ? null : this.endDate,
        'module': this.module === '' ? null : this.module,
        'code_type': this.code_type === '' ? null : this.code_type
      };
      Vue.nextTick(() => this.$refs.vuetable.refresh());
    },
    onFilterReset() {
      this.moreParams = {};
      Vue.nextTick(() => this.$refs.vuetable.refresh());
    },
    onDateFilterReset() {
      this.moreParams = {};
      Vue.nextTick(() => this.$refs.vuetable.refresh());
    },
  },
  mounted() {
    this.$events.$on("filter-set", (eventData) => this.onFilterSet(eventData));
    this.$events.$on("date-set", (eventData) => this.onDateSet(eventData));
    this.$events.$on("filter-reset", () => this.onFilterReset());
    this.$events.$on("date-filter-reset", () => this.onDateFilterReset());
    this.$events.$on('module-set', eventData => this.onModuleFilterSet(eventData))
    this.$events.$on('code-type-set', eventData => this.onCodeTypeFilterSet(eventData))
  },
};
</script>
<style>
/* Absolute Center Spinner */
.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: visible;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.loading:before {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}
</style>
<style src="spinkit/scss/spinkit.scss" lang="scss" />
